import axios from 'axios';
import { User } from '../types/user';
import { CurrentUserType } from '../contexts/UserContext';

export async function signIn(params: {baseUrl: string, email: string, password: string}): Promise<CurrentUserType> {
  const res = await axios.post<{data: User}>(`${params.baseUrl}/api/auth/sign_in`, params);
  return {
    user: res.data.data,
    auth: {
      uid: res.headers['uid'],
      client: res.headers['client'],
      token: res.headers['access-token']
    }
  };
}
