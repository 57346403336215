import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { Header } from '../components/Header';
import { Loading, LoadingButton } from '../components/Loading';
import { Flatten, PropType } from '../types/core';
import {
  useTrialsQuery,
  useSimulateAllocationLazyQuery,
  TrialsQuery
} from '../types/graphql';

type TrialsType = PropType<TrialsQuery, 'trials'>;
type TrialType = Flatten<TrialsType>;
type SheetType = Flatten<PropType<TrialType, 'sheets'>>;
type AllocationType = PropType<SheetType, 'allocation'>;

type Field = {
  name: string,
  value: boolean
};

type EnrollmentType = {
  number: number,
  departmentIndex: number
  fields: Field[]
};

type SimulationType = {
  enrollments: EnrollmentType[],
  randomizedCases: string[],
  ptoshCases: string[],
};

type SimulationHeaderProps = {
  allocation: AllocationType,
  departments: number,
  simulations: SimulationType[],
  fieldName: 'randomizedCases' | 'ptoshCases'
};

function SimulationTable({ simulations, allocation, departments, fieldName }: SimulationHeaderProps) {
  const { t } = useTranslation();
  const fields = simulations[0].enrollments[0].fields.map(f => f.name);
  const groups = allocation!.groups || [];
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>{t('ratio')}({groups.map(g => g.code).join(':')})</th>
          {simulations.map((_, i) => (<th key={i}>{t('n_time', { n: i + 1 })}</th>))}
        </tr>
      </thead>
      <tbody>
        {fields.map((f, j) => (
          <tr key={j}>
            <th>{f}</th>
            {simulations.map((simulation, k) => (
              <td key={k}>
                {groups.map(group =>
                  simulation[fieldName].flatMap((g2, number) => ({
                    group: g2,
                    value: (simulation.enrollments[number].fields.find(f2 => f2.name === f)?.value as boolean)
                  })).filter((g) => (g.group === group.code && g.value)).length
                ).join(':')}
              </td>
            ))}
          </tr>
        ))}
        {[...Array(departments)].map((_, departmentIndex) => (
          <tr key={departmentIndex}>
            <th>{t('facility_number')}{departmentIndex}</th>
            {simulations.map((simulation, k) => (
              <td key={k}>
                {groups.map(group => (
                  simulation[fieldName].filter((code, i) => ((simulation.enrollments[i].departmentIndex === departmentIndex) && (code === group.code))).length.toString()
                )).join(':')}
              </td>
            ))}
          </tr>
        ))}
        <tr>
          <th>{t('all')}</th>
          {simulations.map((simulation, k) => (
            <td key={k}>
              {groups.map(g => (simulation[fieldName].filter(code => code === g.code)).length.toString()).join(':')}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
}

function SimulationDetail({ simulation, number }: {simulation: SimulationType, number: number}) {
  const { t } = useTranslation();
  return (
    <>
      <h4>{t('n_time', {n: number})}</h4>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{t('enrollment_number')}</th>
            <th>{t('facility_number')}</th>
            <th>{t('group_random')}</th>
            <th>{t('group_ptosh')}</th>
            {simulation.enrollments[0].fields.map((f, i) => (<th key={i}>{f.name}</th>))}
          </tr>
        </thead>
        <tbody>
          {simulation.enrollments.map((e, i) => (
            <tr key={i}>
              <td>{e.number}</td>
              <td>{e.departmentIndex}</td>
              <td>{simulation.randomizedCases[i]}</td>
              <td>{simulation.ptoshCases[i]}</td>
              {e.fields.map((f, j) => (<td key={j}>{f.value ? 'true' : 'false'}</td>))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

function SimulationDetails({ simulations }: {simulations: SimulationType[]}) {
  return (
    <>
      {simulations.map((simulation, i) =>(
        <SimulationDetail key={i} simulation={simulation} number={i + 1}/>
      ))}
    </>
  );
}

function SimulationResult({ allocation, departments, simulations }: {allocation: AllocationType, departments: number, simulations: SimulationType[]}) {
  const { t } = useTranslation();
  if (simulations.length === 0) {
    return null;
  }

  return (
    <div>
      <h3>{t('allocation_results')} ({t('random')})</h3>
      <SimulationTable allocation={allocation} departments={departments} simulations={simulations} fieldName="randomizedCases"/>
      <h3>{t('allocation_results')} ({t('ptosh')})</h3>
      <SimulationTable allocation={allocation} departments={departments} simulations={simulations} fieldName="ptoshCases"/>
      <h3>{t('allocation_results')}</h3>
      <SimulationDetails simulations={simulations}/>
    </div>
  );
}

function Simulatioon({trial, sheet, allocation, attempts, departments, cases}: {trial: string, sheet: string, allocation: AllocationType, attempts: number, departments: number, cases: number}) {
  const { t } = useTranslation();
  const [simulations, setSimulations] = useState<SimulationType[]>([]);
  const [startSimulation, { loading, error }] = useSimulateAllocationLazyQuery({
    onCompleted: d => setSimulations(d.simulateAllocation)
  });

  useEffect(() => {
    setSimulations([]);
  }, [trial, sheet, attempts, departments, cases]);

  return (
    <>
      <div>
        <LoadingButton loading={loading} className="my-2" onClick={() => startSimulation({ variables: { trial, sheet, attempts, departments, cases } })}>
          {t('start')}
        </LoadingButton>
      </div>
      {error && <p>Error! </p>}
      {loading && <Loading />}
      <SimulationResult allocation={allocation} departments={departments} simulations={simulations} />
    </>
  );
}

export function SimulateAllocationPage() {
  const { t } = useTranslation();
  const [trials, setTrials] = useState<TrialsType>([])
  const [selectedTrial, setSelectedTrial] = useState<TrialType | undefined>();
  const [selectedSheet, setSelectedSheet] = useState<SheetType | undefined>();
  const [attempts, setAttempts] = useState(10);
  const [departments, setDepartments] = useState(3);
  const [cases, setCases] = useState(10);
  const { loading, error, data } = useTrialsQuery({
    onCompleted: d => {
      const ts = d.trials.reduce<TrialsType>((acc, t) => {
        const sheets = t.sheets.filter(s => s.allocation?.allocationMethod === 'automatic');
        if (sheets.length > 0) {
          acc.push({...t, sheets});
        }
        return acc;
      }, []);
      if (ts.length === 0) {
        return;
      }
      setTrials(ts);
      setSelectedTrial(ts[0]);
      setSelectedSheet(ts[0].sheets[0]);
    }
  });

  if (error) return (<p>Error! </p>);
  if (loading || !data) return (<Loading />);

  const handleTrialChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const changedTrial = trials.find(t => t.name === e.target.value);
    setSelectedTrial(changedTrial);
    setSelectedSheet(changedTrial?.sheets[0]);
  };

  return (
    <div>
      <Header />
      <div className="container">
        <h1>{t('shimulate_allocation')}</h1>
        <div>
          <label htmlFor="trial" className="form-label">{t('trial')}</label>
          <select className="form-control" value={selectedTrial?.name} onChange={handleTrialChange}>
            {trials.map((t, i) => (<option value={t.name} key={i}>{t.name}</option>))}
          </select>
          <label htmlFor="trial" className="form-label">{t('trial_allocation_sheet')}</label>
          <select className="form-control" value={selectedSheet?.aliasName || undefined} onChange={(e) => setSelectedSheet(selectedTrial?.sheets.find(t => t.aliasName === e.target.value))}>
            {selectedTrial?.sheets.map((s, i) => (
              <option value={s.aliasName|| undefined} key={i}>
                {`${s.name}${s.allocation?.isZelen ? t('is_zelen') : ''}`}
              </option>
            ))}
          </select>
          <label>{t('attempts')}</label>
          <input type="text" className="form-control" defaultValue={attempts} onChange={(e) => setAttempts(parseInt(e.target.value))}/>
          <label>{t('departments')}</label>
          <input type="text" className="form-control" defaultValue={departments} onChange={(e) => setDepartments(parseInt(e.target.value))}/>
          <label>{t('cases')}</label>
          <input type="text" className="form-control" defaultValue={cases} onChange={(e) => setCases(parseInt(e.target.value))}/>
        </div>
        <Simulatioon
          trial={selectedTrial?.name || ''}
          sheet={selectedSheet?.aliasName || ''}
          allocation={selectedSheet?.allocation}
          attempts={attempts}
          departments={departments}
          cases={cases}
        />
      </div>
    </div>
  );
}
