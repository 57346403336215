import React from 'react';
import { useTranslation } from "react-i18next";

export function Loading() {
  return (
    <div className="spinner-grow" role="status" />
  );
}

export function Spinner() {
  return (
    <span className="spinner-border spinner-border-sm" role="status" />
  );
}

type ButtonProps = React.DOMAttributes<HTMLButtonElement> & {loading: boolean, className?: string};

export function LoadingButton(props: ButtonProps) {
  const { t } = useTranslation();
  const {loading, className, children, ...others} = props;
  const newClassName = className + ' btn btn-primary';

  return (
    <button className={newClassName} disabled={loading} {...others}>
      {
        loading ? (
          <>
            <Spinner />
            <span className="ms-2">{t('loading')}</span>
          </>
        ) : (
          children
        )
      }
    </button>
  );
}
