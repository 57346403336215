import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';

import { UserContext, CurrentUserType } from '../contexts/UserContext';
import { signIn } from '../api/auth'

type StateType = {
  email: string,
  password: string,
};

const InitialState: StateType = {
  email: '',
  password: '',
};

function Form(props: {setCurrentUser: (v: CurrentUserType) => void, baseUrl: string}) {
  const [state, setState] = useState(InitialState);
    
  async function onSubmit() {
    try {
      const currentUser = await signIn({...state, baseUrl: props.baseUrl});
      props.setCurrentUser(currentUser);
    }
    catch(e) {}
  }
  return (
    <>
      <label>email</label>
      <input type="email" className="form-control" onChange={(e) => setState({...state, email: e.target.value})}/>
      <label>Password</label>
      <input type="password" className="form-control" onChange={(e) => setState({...state, password: e.target.value})}/>
      <input type="submit" className="btn btn-primary" onClick={() => onSubmit()}/>
    </>
  );
}

function Login(props: {setCurrentUser: (v: CurrentUserType) => void, baseUrl?: string}) {
  return (
    <div className="container">
      <h1>Ptosh simulator</h1>
      <div>baseUrl: {props.baseUrl}</div>
      {
        props.baseUrl ? (
          <Form setCurrentUser={props.setCurrentUser} baseUrl={props.baseUrl}/>
        ) : (
          <div>No baseUrl</div>
        )
      }
    </div>
  )
}
export function LoginPage() {
  return (
    <UserContext.Consumer>
      {
        ({currentUser, setCurrentUser, baseUrl}) => {
          return (
            currentUser ?
            <Redirect to="/" /> :
            <Login setCurrentUser={setCurrentUser} baseUrl={baseUrl} />
          );
        }
      }
    </UserContext.Consumer>
  );
}
