import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded date */
  ISO8601Date: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type Account = {
  __typename?: 'Account';
  clientId: Scalars['String'];
  createdAt: Scalars['ISO8601DateTime'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isSuspended: Scalars['Boolean'];
  phone: Scalars['String'];
  pin: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Allocation = {
  __typename?: 'Allocation';
  allocationMethod: Scalars['String'];
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  doubleBlindEmails?: Maybe<Scalars['String']>;
  groups?: Maybe<Array<AllocationGroup>>;
  id: Scalars['ID'];
  isDoubleBlinded: Scalars['Boolean'];
  isZelen: Scalars['Boolean'];
  sheet: Sheet;
  sheetId: Scalars['ID'];
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  zelenImbalance?: Maybe<Scalars['Int']>;
};

export type AllocationGroup = {
  __typename?: 'AllocationGroup';
  allocatees: Array<Scalars['Int']>;
  code: Scalars['String'];
  if: Scalars['String'];
  label: Scalars['String'];
  message: Scalars['String'];
};

export type Answer = {
  __typename?: 'Answer';
  createdAt: Scalars['ISO8601DateTime'];
  eproQuestion: Question;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
  value: Scalars['JSON'];
};

export type Artifact = {
  __typename?: 'Artifact';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id?: Maybe<Scalars['ID']>;
  isEmpty: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  nameEn?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  no: Scalars['String'];
  persisted: Scalars['Boolean'];
  trial?: Maybe<Trial>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['String'];
};

export enum Authority {
  /** read */
  Read = 'read',
  /** write */
  Write = 'write'
}

export type CompletedQuestionnaire = {
  __typename?: 'CompletedQuestionnaire';
  createdAt: Scalars['ISO8601DateTime'];
  eproAccount: Account;
  eproAnswers: Array<Answer>;
  eproSchedule: Schedule;
  id: Scalars['ID'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type CreateCompletedQuestionnaireInput = {
  eproQuestionId: Scalars['ID'];
  value: Scalars['JSON'];
};

/** Autogenerated return type of CreateCompletedQuestionnaire */
export type CreateCompletedQuestionnairePayload = {
  __typename?: 'CreateCompletedQuestionnairePayload';
  completedQuestionnaire: CompletedQuestionnaire;
};

/** Autogenerated return type of CreateQuestionnaire */
export type CreateQuestionnairePayload = {
  __typename?: 'CreateQuestionnairePayload';
  errors: Array<Scalars['String']>;
  questionnaire: Questionnaire;
};

/** Autogenerated return type of CreateSheet */
export type CreateSheetPayload = {
  __typename?: 'CreateSheetPayload';
  errors: Array<Scalars['String']>;
  sheet: Sheet;
};

/** Autogenerated return type of DeleteDocument */
export type DeleteDocumentPayload = {
  __typename?: 'DeleteDocumentPayload';
  errors: Array<Scalars['String']>;
};

/** Autogenerated return type of DeleteQuestionnaire */
export type DeleteQuestionnairePayload = {
  __typename?: 'DeleteQuestionnairePayload';
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteReminder */
export type DeleteReminderPayload = {
  __typename?: 'DeleteReminderPayload';
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteSheet */
export type DeleteSheetPayload = {
  __typename?: 'DeleteSheetPayload';
  id: Scalars['ID'];
};

export type Department = {
  __typename?: 'Department';
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  extension?: Maybe<Scalars['String']>;
  facility?: Maybe<Facility>;
  fax?: Maybe<Scalars['String']>;
  head?: Maybe<User>;
  id: Scalars['ID'];
  lastVerifyOn?: Maybe<Scalars['ISO8601Date']>;
  nameEn?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  tel?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  uuid?: Maybe<Scalars['String']>;
};

export type Document = {
  __typename?: 'Document';
  artifactNo: Scalars['String'];
  domain: Scalars['String'];
  etag: Scalars['String'];
  key: Scalars['String'];
  lastModifiedAt: Scalars['ISO8601DateTime'];
  name: Scalars['String'];
  parent: Scalars['String'];
  path: Scalars['String'];
  trial: Scalars['String'];
};

export type DocumentFile = {
  __typename?: 'DocumentFile';
  url: Scalars['String'];
};

export type Enrollment = {
  __typename?: 'Enrollment';
  departmentIndex: Scalars['Int'];
  fields: Array<Field>;
  group: Scalars['String'];
  number: Scalars['Int'];
};

export type Facility = {
  __typename?: 'Facility';
  address?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  directorFirstname?: Maybe<Scalars['String']>;
  directorLastname?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  nameEn?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Prefecture>;
  prefectureCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  uuid?: Maybe<Scalars['String']>;
};

export type Field = {
  __typename?: 'Field';
  name: Scalars['String'];
  value: Scalars['Boolean'];
};

export type FieldItem = {
  __typename?: 'FieldItem';
  argumentType?: Maybe<FieldItemArgument>;
  autoCalcField?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  defaultValue?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  deviation?: Maybe<Scalars['String']>;
  fieldType?: Maybe<FieldItemFieldType>;
  formulaField?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isInvisible?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  linkType?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  normalRange?: Maybe<Scalars['JSON']>;
  option?: Maybe<Option>;
  optionId?: Maybe<Scalars['ID']>;
  referenceField?: Maybe<Scalars['String']>;
  referenceType?: Maybe<FieldItemReference>;
  review?: Maybe<Scalars['Boolean']>;
  seq?: Maybe<Scalars['Int']>;
  sheetId?: Maybe<Scalars['ID']>;
  termCode?: Maybe<Scalars['String']>;
  type?: Maybe<FieldItemKind>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  validators?: Maybe<Scalars['JSON']>;
};

export enum FieldItemArgument {
  Empty = 'empty',
  Master = 'master',
  Sheet = 'sheet'
}

export enum FieldItemFieldType {
  Address = 'address',
  Autocomplete = 'autocomplete',
  CheckBox = 'check_box',
  Ctcae = 'ctcae',
  Date = 'date',
  Datetime = 'datetime',
  Dead = 'dead',
  Department = 'department',
  Disease = 'disease',
  Drug = 'drug',
  GeneralDisease = 'general_disease',
  Meddra = 'meddra',
  RadioButton = 'radio_button',
  SaeReport = 'sae_report',
  Select = 'select',
  Sex = 'sex',
  Text = 'text',
  TextArea = 'text_area',
  User = 'user'
}

export enum FieldItemKind {
  Allocation = 'allocation',
  Article = 'article',
  Assigned = 'assigned',
  AutoCalc = 'auto_calc',
  Heading = 'heading',
  Note = 'note',
  Reference = 'reference'
}

export enum FieldItemReference {
  Master = 'master',
  Method = 'method',
  Sheet = 'sheet'
}

export type IncompleteQuestionnaire = {
  __typename?: 'IncompleteQuestionnaire';
  begin: Scalars['ISO8601Date'];
  createdAt: Scalars['ISO8601DateTime'];
  end?: Maybe<Scalars['ISO8601Date']>;
  eproAccount: Account;
  eproSchedule: Schedule;
  id: Scalars['ID'];
  snapshot: Scalars['JSON'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum Locale {
  /** en */
  En = 'en',
  /** ja */
  Ja = 'ja'
}

export type Mutation = {
  __typename?: 'Mutation';
  createEproCompletedQuestionnaire: CreateCompletedQuestionnairePayload;
  createEproQuestionnaire: CreateQuestionnairePayload;
  createSheet: CreateSheetPayload;
  deleteEproQuestionnaire: DeleteQuestionnairePayload;
  deleteEproReminder: DeleteReminderPayload;
  deleteSheet: DeleteSheetPayload;
  deleteTmfDocument?: Maybe<DeleteDocumentPayload>;
  publishTmfArtifact?: Maybe<PublishArtifactPayload>;
  removeTmfArtifact?: Maybe<RemoveArtifactPayload>;
  updateEproAccount: UpdateAccountPayload;
  updateEproQuestionnaire: UpdateQuestionnairePayload;
  updateEproSchedules: UpdateSchedulesPayload;
  updateSheet: UpdateSheetPayload;
  updateSheetLock: UpdateSheetLockPayload;
  uploadTmfDocument?: Maybe<UploadDocumentPayload>;
  upsertEproReminder: UpsertReminderPayload;
};


export type MutationCreateEproCompletedQuestionnaireArgs = {
  clientId: Scalars['String'];
  incompleteId: Scalars['ID'];
  input: Array<CreateCompletedQuestionnaireInput>;
  pin: Scalars['String'];
};


export type MutationCreateEproQuestionnaireArgs = {
  trial: Scalars['String'];
};


export type MutationCreateSheetArgs = {
  aliasName: Scalars['String'];
  category: SheetCategoryInput;
  trial: Scalars['String'];
};


export type MutationDeleteEproQuestionnaireArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteEproReminderArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSheetArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTmfDocumentArgs = {
  artifact: Scalars['String'];
  path: Scalars['String'];
  trial: Scalars['String'];
};


export type MutationPublishTmfArtifactArgs = {
  artifact: Scalars['String'];
  publish: Scalars['Boolean'];
  trial: Scalars['String'];
};


export type MutationRemoveTmfArtifactArgs = {
  artifact: Scalars['String'];
  trial: Scalars['String'];
};


export type MutationUpdateEproAccountArgs = {
  clientId: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  pin: Scalars['String'];
};


export type MutationUpdateEproQuestionnaireArgs = {
  input: UpdateQuestionnaireInput;
};


export type MutationUpdateEproSchedulesArgs = {
  input: Array<UpdateScheduleInput>;
  trial: Scalars['String'];
};


export type MutationUpdateSheetArgs = {
  input: UpdateSheetInput;
};


export type MutationUpdateSheetLockArgs = {
  id: Scalars['ID'];
  locked: Scalars['Boolean'];
};


export type MutationUploadTmfDocumentArgs = {
  artifact: Scalars['String'];
  path: Scalars['String'];
  trial: Scalars['String'];
};


export type MutationUpsertEproReminderArgs = {
  input: UpsertReminderInput;
  trial: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  controlledTerminologyId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  digest?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtensible?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  odmId?: Maybe<Scalars['String']>;
  parity?: Maybe<Scalars['String']>;
  sourceId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  uuid?: Maybe<Scalars['String']>;
  values?: Maybe<Array<OptionValue>>;
};

export type OptionValue = {
  __typename?: 'OptionValue';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  isUsable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  seq?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export type Organization = {
  __typename?: 'Organization';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['Int'];
  isGeneralPurpose: Scalars['Boolean'];
  name: Scalars['String'];
  properName: Scalars['String'];
  trials: Array<Trial>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Prefecture = {
  __typename?: 'Prefecture';
  code?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  kana?: Maybe<Scalars['String']>;
  nameEn?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated return type of PublishArtifact */
export type PublishArtifactPayload = {
  __typename?: 'PublishArtifactPayload';
  artifact: Artifact;
  errors: Array<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  /** Abilityを取得する */
  ability: Scalars['Boolean'];
  /** アクセス可能な試験情報の一覧を取得する */
  accessibleTrials: Array<Trial>;
  /** アクセス可能な割付情報の一覧を取得する */
  allocations: Array<Allocation>;
  /** 指定した試験のTMF Artifactの一覧を取得する */
  artifacts: Array<Artifact>;
  /** ログインユーザーを取得する */
  currentUser: User;
  /** 指定した試験の指定したファイルを取得する */
  downloadTmfDocument: DocumentFile;
  /** eproアカウント情報を取得する */
  eproAccount?: Maybe<Account>;
  /** 回答済みの質問を取得する */
  eproCompletedQuestionnaire: CompletedQuestionnaire;
  /** 回答済みの質問を取得する */
  eproCompletedQuestionnaires: Array<CompletedQuestionnaire>;
  /** 未回答の質問を取得する */
  eproIncompleteQuestionnaire: IncompleteQuestionnaire;
  /** 未回答の質問を取得する */
  eproIncompleteQuestionnaires: Array<IncompleteQuestionnaire>;
  /** eProのアンケートを取得する */
  eproQuestionnaire: Questionnaire;
  /** eProのアンケートを取得する */
  eproQuestionnaires: Array<Questionnaire>;
  /** eProの基準日を取得する */
  eproReferenceDates: Array<ReferenceDate>;
  /** eProのリマインドを取得する */
  eproReminder: Reminder;
  /** eProのスケジュールを取得する */
  eproSchedules: Array<Schedule>;
  /** オプションを取得する */
  option: Option;
  /** オプション一覧を取得する */
  options: Array<Option>;
  /** シートを取得する */
  sheet: Sheet;
  /** アクセス可能なシートの一覧を取得する */
  sheets: Array<Sheet>;
  /** 指定した割付シートのシミュレーションを行う */
  simulateAllocation: Array<Simulation>;
  /** 指定した試験の指定したTMF Artifactのファイル一覧を取得する */
  tmfDocuments: Array<Document>;
  /** アクセス可能な試験情報を取得する */
  trial: Trial;
  /** 試験の権限を取得する */
  trialAuthority: TrialAuthority;
  /** アクセス可能な試験情報の一覧を取得する */
  trials: Array<Trial>;
};


export type QueryAbilityArgs = {
  action: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  model: Scalars['String'];
};


export type QueryAllocationsArgs = {
  allocationMethod?: InputMaybe<Scalars['String']>;
};


export type QueryArtifactsArgs = {
  trial: Scalars['String'];
};


export type QueryDownloadTmfDocumentArgs = {
  artifact: Scalars['String'];
  key: Scalars['String'];
  trial: Scalars['String'];
};


export type QueryEproAccountArgs = {
  clientId: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryEproCompletedQuestionnaireArgs = {
  clientId: Scalars['String'];
  id: Scalars['ID'];
  pin: Scalars['String'];
};


export type QueryEproCompletedQuestionnairesArgs = {
  clientId: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryEproIncompleteQuestionnaireArgs = {
  clientId: Scalars['String'];
  id: Scalars['ID'];
  pin: Scalars['String'];
};


export type QueryEproIncompleteQuestionnairesArgs = {
  clientId: Scalars['String'];
  pin: Scalars['String'];
};


export type QueryEproQuestionnaireArgs = {
  id: Scalars['ID'];
  trial: Scalars['String'];
};


export type QueryEproQuestionnairesArgs = {
  trial: Scalars['String'];
};


export type QueryEproReferenceDatesArgs = {
  trial: Scalars['String'];
};


export type QueryEproReminderArgs = {
  id: Scalars['ID'];
  trial: Scalars['String'];
};


export type QueryEproSchedulesArgs = {
  trial: Scalars['String'];
};


export type QueryOptionArgs = {
  id: Scalars['ID'];
};


export type QueryOptionsArgs = {
  trial: Scalars['String'];
};


export type QuerySheetArgs = {
  id: Scalars['ID'];
};


export type QuerySheetsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QuerySimulateAllocationArgs = {
  attempts: Scalars['Int'];
  cases: Scalars['Int'];
  departments: Scalars['Int'];
  sheet: Scalars['String'];
  trial: Scalars['String'];
};


export type QueryTmfDocumentsArgs = {
  artifact: Scalars['String'];
  trial: Scalars['String'];
};


export type QueryTrialArgs = {
  name: Scalars['String'];
};


export type QueryTrialAuthorityArgs = {
  trial: Scalars['String'];
};


export type QueryTrialsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Question = {
  __typename?: 'Question';
  config: Scalars['JSON'];
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  label: Scalars['String'];
  seq: Scalars['Int'];
  type: QuestionKind;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type QuestionInput = {
  config: Scalars['JSON'];
  id?: InputMaybe<Scalars['ID']>;
  label: Scalars['String'];
  seq: Scalars['Int'];
  type: QuestionKind;
};

export enum QuestionKind {
  /** check */
  Check = 'check',
  /** radio */
  Radio = 'radio',
  /** slider */
  Slider = 'slider',
  /** text */
  Text = 'text'
}

export type Questionnaire = {
  __typename?: 'Questionnaire';
  canEdit: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  eproQuestions: Array<Question>;
  id: Scalars['ID'];
  label: Scalars['String'];
  trial: Trial;
  updatedAt: Scalars['ISO8601DateTime'];
};

export enum Reference {
  /** begin */
  Begin = 'begin',
  /** end */
  End = 'end'
}

export type ReferenceDate = {
  __typename?: 'ReferenceDate';
  createdAt: Scalars['ISO8601DateTime'];
  id: Scalars['ID'];
  isYearly: Scalars['Boolean'];
  label: Scalars['String'];
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Reminder = {
  __typename?: 'Reminder';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['ISO8601DateTime'];
  days: Scalars['Int'];
  eproSchedule: Schedule;
  id: Scalars['ID'];
  referenceType: Reference;
  sendingTime: Scalars['ISO8601DateTime'];
  subject?: Maybe<Scalars['String']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

/** Autogenerated return type of RemoveArtifact */
export type RemoveArtifactPayload = {
  __typename?: 'RemoveArtifactPayload';
  errors?: Maybe<Array<Scalars['String']>>;
};

export enum Role {
  /** admin */
  Admin = 'admin',
  /** dm */
  Dm = 'dm',
  /** monitor */
  Monitor = 'monitor',
  /** user */
  User = 'user'
}

export type Schedule = {
  __typename?: 'Schedule';
  canEdit: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  days: Scalars['Int'];
  eproQuestionnaire: Questionnaire;
  eproReferenceDate: ReferenceDate;
  eproReminders: Array<Reminder>;
  id: Scalars['ID'];
  periodDays?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type Sheet = {
  __typename?: 'Sheet';
  aliasName?: Maybe<Scalars['String']>;
  allocation?: Maybe<Allocation>;
  canDelete?: Maybe<Scalars['Boolean']>;
  canEdit?: Maybe<Scalars['Boolean']>;
  category?: Maybe<SheetCategory>;
  createdAt?: Maybe<Scalars['ISO8601DateTime']>;
  faxStylesheet?: Maybe<Scalars['String']>;
  fieldItems?: Maybe<Array<FieldItem>>;
  id: Scalars['ID'];
  imagesCount?: Maybe<Scalars['Int']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isLocked?: Maybe<Scalars['Boolean']>;
  isSerious?: Maybe<Scalars['Boolean']>;
  javascript?: Maybe<Scalars['String']>;
  labDepartment?: Maybe<Department>;
  labDepartmentId?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  odm?: Maybe<Scalars['JSON']>;
  registrationConfig?: Maybe<Scalars['JSON']>;
  review?: Maybe<Scalars['Boolean']>;
  seq?: Maybe<Scalars['Int']>;
  sourceId?: Maybe<Scalars['String']>;
  stylesheet?: Maybe<Scalars['String']>;
  trial?: Maybe<Trial>;
  trialId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
};

export enum SheetCategory {
  AeReport = 'ae_report',
  Allocation = 'allocation',
  CentralLab = 'central_lab',
  CommitteesOpinion = 'committees_opinion',
  Entry = 'entry',
  Multiple = 'multiple',
  Ordered = 'ordered',
  Registration = 'registration',
  Undefined = 'undefined'
}

export enum SheetCategoryInput {
  AeReport = 'ae_report',
  Allocation = 'allocation',
  CentralLab = 'central_lab',
  CommitteesOpinion = 'committees_opinion',
  Entry = 'entry',
  Multiple = 'multiple',
  Ordered = 'ordered',
  Registration = 'registration',
  SaeReport = 'sae_report',
  Undefined = 'undefined'
}

export type Simulation = {
  __typename?: 'Simulation';
  enrollments: Array<Enrollment>;
  ptoshCases: Array<Scalars['String']>;
  randomizedCases: Array<Scalars['String']>;
};

export type Trial = {
  __typename?: 'Trial';
  aeCommitteeMembers: Array<User>;
  closedOn?: Maybe<Scalars['ISO8601Date']>;
  closedRegistrationOn?: Maybe<Scalars['ISO8601Date']>;
  committeeMembers: Array<User>;
  createdAt: Scalars['ISO8601DateTime'];
  dataManagers: Array<User>;
  eproQuestionnaires: Array<Questionnaire>;
  eproReferenceDates: Array<ReferenceDate>;
  eproSchedules: Array<Schedule>;
  id: Scalars['ID'];
  isEproAvailable: Scalars['Boolean'];
  isObservationStudy: Scalars['Boolean'];
  isRegistrational: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  isUploadable?: Maybe<Scalars['Boolean']>;
  labUsers: Array<User>;
  monitors: Array<User>;
  name: Scalars['String'];
  openedOn?: Maybe<Scalars['ISO8601Date']>;
  options?: Maybe<Array<Option>>;
  organization: Organization;
  primaryInvestigator?: Maybe<User>;
  properName: Scalars['String'];
  registryIdentifier?: Maybe<Scalars['String']>;
  secretariats: Array<User>;
  sheets: Array<Sheet>;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TrialAuthority = {
  __typename?: 'TrialAuthority';
  authority?: Maybe<Authority>;
  trial: Trial;
};

/** Autogenerated return type of UpdateAccount */
export type UpdateAccountPayload = {
  __typename?: 'UpdateAccountPayload';
  account: Account;
};

export type UpdateAllocationGroupInput = {
  code?: InputMaybe<Scalars['String']>;
  if?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
};

export type UpdateAllocationInput = {
  allocationMethod?: InputMaybe<Scalars['String']>;
  doubleBlindEmails?: InputMaybe<Scalars['String']>;
  groups?: InputMaybe<Array<UpdateAllocationGroupInput>>;
  isDoubleBlinded?: InputMaybe<Scalars['Boolean']>;
  isZelen?: InputMaybe<Scalars['Boolean']>;
  zelenImbalance?: InputMaybe<Scalars['Int']>;
};

export type UpdateFieldItemInput = {
  argumentType?: InputMaybe<FieldItemArgument>;
  autoCalcField?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  deviation?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<FieldItemFieldType>;
  formulaField?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  isInvisible?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  level?: InputMaybe<Scalars['Int']>;
  linkType?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  normalRange?: InputMaybe<Scalars['JSON']>;
  optionId?: InputMaybe<Scalars['ID']>;
  referenceField?: InputMaybe<Scalars['String']>;
  referenceType?: InputMaybe<FieldItemReference>;
  seq?: InputMaybe<Scalars['Int']>;
  termCode?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FieldItemKind>;
  validators?: InputMaybe<Scalars['JSON']>;
};

export type UpdateQuestionnaireInput = {
  eproQuestions: Array<QuestionInput>;
  id: Scalars['ID'];
  label: Scalars['String'];
};

/** Autogenerated return type of UpdateQuestionnaire */
export type UpdateQuestionnairePayload = {
  __typename?: 'UpdateQuestionnairePayload';
  questionnaire: Questionnaire;
};

export type UpdateScheduleInput = {
  days: Scalars['Int'];
  eproQuestionnaireId: Scalars['ID'];
  eproReferenceDateId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  periodDays?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of UpdateSchedules */
export type UpdateSchedulesPayload = {
  __typename?: 'UpdateSchedulesPayload';
  schedules: Array<Schedule>;
};

export type UpdateSheetInput = {
  aliasName?: InputMaybe<Scalars['String']>;
  allocation?: InputMaybe<UpdateAllocationInput>;
  category?: InputMaybe<SheetCategory>;
  faxStylesheet?: InputMaybe<Scalars['String']>;
  fieldItems?: InputMaybe<Array<UpdateFieldItemInput>>;
  id: Scalars['ID'];
  imagesCount?: InputMaybe<Scalars['Int']>;
  isClosed?: InputMaybe<Scalars['Boolean']>;
  isLocked?: InputMaybe<Scalars['Boolean']>;
  isSerious?: InputMaybe<Scalars['Boolean']>;
  javascript?: InputMaybe<Scalars['String']>;
  labDepartmentId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  odm?: InputMaybe<Scalars['JSON']>;
  registrationConfig?: InputMaybe<Scalars['JSON']>;
  seq?: InputMaybe<Scalars['Int']>;
  sourceId?: InputMaybe<Scalars['String']>;
  stylesheet?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateSheetLock */
export type UpdateSheetLockPayload = {
  __typename?: 'UpdateSheetLockPayload';
  errors: Array<Scalars['String']>;
  sheet: Sheet;
};

/** Autogenerated return type of UpdateSheet */
export type UpdateSheetPayload = {
  __typename?: 'UpdateSheetPayload';
  errors: Array<Scalars['String']>;
  sheet: Sheet;
};

/** Autogenerated return type of UploadDocument */
export type UploadDocumentPayload = {
  __typename?: 'UploadDocumentPayload';
  document: Document;
  errors: Array<Scalars['String']>;
};

export type UpsertReminderInput = {
  body: Scalars['String'];
  days: Scalars['Int'];
  eproScheduleId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  referenceType: Reference;
  subject: Scalars['String'];
};

/** Autogenerated return type of UpsertReminder */
export type UpsertReminderPayload = {
  __typename?: 'UpsertReminderPayload';
  reminder: Reminder;
};

export type User = {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime'];
  department?: Maybe<Department>;
  email: Scalars['String'];
  firstname: Scalars['String'];
  firstnameEn: Scalars['String'];
  id: Scalars['ID'];
  isDoctor: Scalars['Boolean'];
  isEmpowered: Scalars['Boolean'];
  isSuspended: Scalars['Boolean'];
  lastname: Scalars['String'];
  lastnameEn: Scalars['String'];
  locale: Locale;
  role: Role;
  updatedAt: Scalars['ISO8601DateTime'];
};

export type TrialsQueryVariables = Exact<{ [key: string]: never; }>;


export type TrialsQuery = { __typename?: 'Query', trials: Array<{ __typename?: 'Trial', name: string, sheets: Array<{ __typename?: 'Sheet', name?: string | null | undefined, aliasName?: string | null | undefined, allocation?: { __typename?: 'Allocation', isZelen: boolean, zelenImbalance?: number | null | undefined, allocationMethod: string, sheetId: string, groups?: Array<{ __typename?: 'AllocationGroup', label: string, code: string }> | null | undefined } | null | undefined }> }> };

export type SimulateAllocationQueryVariables = Exact<{
  trial: Scalars['String'];
  sheet: Scalars['String'];
  attempts: Scalars['Int'];
  departments: Scalars['Int'];
  cases: Scalars['Int'];
}>;


export type SimulateAllocationQuery = { __typename?: 'Query', simulateAllocation: Array<{ __typename?: 'Simulation', randomizedCases: Array<string>, ptoshCases: Array<string>, enrollments: Array<{ __typename?: 'Enrollment', number: number, departmentIndex: number, fields: Array<{ __typename?: 'Field', name: string, value: boolean }> }> }> };


export const TrialsDocument = gql`
    query trials {
  trials {
    name
    sheets {
      name
      aliasName
      allocation {
        isZelen
        zelenImbalance
        allocationMethod
        sheetId
        groups {
          label
          code
        }
      }
    }
  }
}
    `;

/**
 * __useTrialsQuery__
 *
 * To run a query within a React component, call `useTrialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialsQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrialsQuery(baseOptions?: Apollo.QueryHookOptions<TrialsQuery, TrialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
      }
export function useTrialsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialsQuery, TrialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialsQuery, TrialsQueryVariables>(TrialsDocument, options);
        }
export type TrialsQueryHookResult = ReturnType<typeof useTrialsQuery>;
export type TrialsLazyQueryHookResult = ReturnType<typeof useTrialsLazyQuery>;
export type TrialsQueryResult = Apollo.QueryResult<TrialsQuery, TrialsQueryVariables>;
export const SimulateAllocationDocument = gql`
    query simulateAllocation($trial: String!, $sheet: String!, $attempts: Int!, $departments: Int!, $cases: Int!) {
  simulateAllocation(
    trial: $trial
    sheet: $sheet
    attempts: $attempts
    departments: $departments
    cases: $cases
  ) {
    enrollments {
      number
      departmentIndex
      fields {
        name
        value
      }
    }
    randomizedCases
    ptoshCases
  }
}
    `;

/**
 * __useSimulateAllocationQuery__
 *
 * To run a query within a React component, call `useSimulateAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimulateAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimulateAllocationQuery({
 *   variables: {
 *      trial: // value for 'trial'
 *      sheet: // value for 'sheet'
 *      attempts: // value for 'attempts'
 *      departments: // value for 'departments'
 *      cases: // value for 'cases'
 *   },
 * });
 */
export function useSimulateAllocationQuery(baseOptions: Apollo.QueryHookOptions<SimulateAllocationQuery, SimulateAllocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SimulateAllocationQuery, SimulateAllocationQueryVariables>(SimulateAllocationDocument, options);
      }
export function useSimulateAllocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SimulateAllocationQuery, SimulateAllocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SimulateAllocationQuery, SimulateAllocationQueryVariables>(SimulateAllocationDocument, options);
        }
export type SimulateAllocationQueryHookResult = ReturnType<typeof useSimulateAllocationQuery>;
export type SimulateAllocationLazyQueryHookResult = ReturnType<typeof useSimulateAllocationLazyQuery>;
export type SimulateAllocationQueryResult = Apollo.QueryResult<SimulateAllocationQuery, SimulateAllocationQueryVariables>;