import React, { ReactNode, useState, useEffect } from 'react';
import qs from 'qs';
import URL from 'url-parse'
import { Auth, User } from '../types/user';

export type CurrentUserType = {
  auth: Auth,
  user: User,
}

type UserContextType = {
  currentUser?: CurrentUserType,
  setCurrentUser: (user: CurrentUserType|undefined) => void,
  baseUrl?: string,
}

const BASE_URL_KEY = 'baseUrl';
const CURRENT_USER_KEY = 'ptosh';

export const UserContext = React.createContext({} as UserContextType);

function isValidUrl(url: string) {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
}

export function clearStorage() {
  localStorage.removeItem(CURRENT_USER_KEY);
}

export function UserContextProvider({children}: {children: ReactNode}) {
  const [baseUrl, setBaseUrl] = useState<string|undefined>(undefined);
  const [currentUser, setCurrentUser] = useState<CurrentUserType|undefined>(undefined);
  useEffect(() => {
    const obj = qs.parse(window.location.search.replace(/^\?/, ''));
    const sessionBaseUrl = localStorage.getItem(BASE_URL_KEY) ?? undefined;
    const newBaseUrl = (obj as {baseUrl?: string})?.baseUrl ?? sessionBaseUrl;
    if (newBaseUrl && isValidUrl(newBaseUrl) && baseUrl !== newBaseUrl) {
      localStorage.setItem(BASE_URL_KEY, newBaseUrl);
      setBaseUrl(newBaseUrl);
    }
    
    const json = localStorage.getItem(CURRENT_USER_KEY);
    if (json) {
      setCurrentUser(JSON.parse(json) as CurrentUserType);
    }
  }, []);

  const updateUser = (user: CurrentUserType|undefined) => {
    if (user) {
      localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user));
    } else {
      clearStorage();
    }
    setCurrentUser(user);
  };
  
  return (
    <UserContext.Provider value={{ currentUser, setCurrentUser: updateUser, baseUrl }}>
      {children}
    </UserContext.Provider>
  );
}
