import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { UserContext, CurrentUserType } from '../contexts/UserContext'

function InnerHeader(props: {setCurrentUser: (v?: CurrentUserType) => void}) {
  const { t } = useTranslation();
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">Ptosh Simulator</Link>
        <ul className="navbar-nav me-auto">
          <li className="nav-item"><Link className="nav-link active" aria-current="page" to="/">{t('allocation')}</Link></li>
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item"><button className="btn btn-link nav-link active" onClick={() => props.setCurrentUser(undefined) }>{t('sign_out')}</button></li>
        </ul>
      </div>
    </nav>
  );
}

export const Header = () => (
  <UserContext.Consumer>
    {({ currentUser, setCurrentUser }) => (
      currentUser ? <InnerHeader setCurrentUser={setCurrentUser} /> : <Redirect to='/login' />
    )
    }
  </UserContext.Consumer>
);
