import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteProps,
} from "react-router-dom";

import { LoginPage } from './pages/LoginPage';
import { SimulateAllocationPage } from './pages/SimulateAllocationPage';
import { UserContextProvider, UserContext } from './contexts/UserContext';
import { ApolloContextProvider } from './contexts/ApolloContext';

function PrivateRoute({ children, ...rest }: RouteProps) {
  return (
    <Route {...rest}>
      <UserContext.Consumer>
        {
          ({currentUser, setCurrentUser, baseUrl}) => (
            (currentUser && baseUrl) ? (
              <ApolloContextProvider currentUser={currentUser} setCurrentUser={setCurrentUser} baseUrl={baseUrl}>
                {children}
              </ApolloContextProvider>
            ) : (
              <Redirect
                to={{
                  pathname: "/login",
                  state: { baseUrl }
                }}
              />
          ))
        }
      </UserContext.Consumer>
    </Route>
  );
}

function App() {
  return (
    <UserContextProvider>
      <Router>
        <Switch>
          <Route path="/login"><LoginPage /></Route>
          <PrivateRoute exact path="/"><SimulateAllocationPage /></PrivateRoute>
          <Route><Redirect to="login"/></Route>
        </Switch>
      </Router>
    </UserContextProvider>
  );
}

export default App;
