import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        'sign_out': 'Sign out',
        'allocation': 'Allocation',
        'shimulate_allocation': 'Simulate allocation',
        'trial': 'Trial',
        'trial_allocation_sheet': 'Allocation sheet',
        'attempts': 'Number of attempts',
        'departments': 'Number of facilities',
        'cases': 'Number of cases',
        'start': 'Start simulation',
        'group': 'Group',
        'n_time': '{{n}}th time',
        'enrollment_number': 'Enrollment number',
        'facility_number': 'Facility number',
        'group_random': 'group(Randm)',
        'group_ptosh': 'group(Ptosh)',
        'allocation_results': 'Allocation results',
        'random': 'Random',
        'ptosh': 'Ptosh',
        'all': 'All',
        'ratio': 'Ratio',
        'is_zelen': '(Zelen enabled)'
      },
    },
    ja: {
      translation: {
        'sign_out': 'ログアウト',
        'allocation': '割付',
        'shimulate_allocation': '割付シミュレーション',
        'trial': '試験',
        'trial_allocation_sheet': '割付シート',
        'attempts': '試行回数',
        'departments': '施設数',
        'cases': '症例数',
        'start': 'シミュレーション開始',
        'group': '群',
        'n_time': '{{n}}回目',
        'enrollment_number': '登録番号',
        'facility_number': '施設番号',
        'group_random': 'ランダム割付先',
        'group_ptosh': 'Ptosh割付先',
        'allocation_results': '割付結果',
        'random': 'ランダム',
        'ptosh': 'Ptosh',
        'all': '全体',
        'ratio': '割付比率',
        'is_zelen': '(Zelen有効)'
      },
    },
  },
  lng: 'ja',
  fallbackLng: 'en',
  interpolation: { escapeValue: false },
});
