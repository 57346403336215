import React, { ReactNode } from 'react';
import { createHttpLink, ApolloClient, ApolloProvider, InMemoryCache, ServerError } from '@apollo/client';
import { ApolloLink } from '@apollo/client/link/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { CurrentUserType, clearStorage } from './UserContext'

type ApolloContextProviderProps = {
  children: ReactNode,
  currentUser: CurrentUserType,
  setCurrentUser: (user: CurrentUserType|undefined) => void,
  baseUrl: string
};

export function ApolloContextProvider(props: ApolloContextProviderProps) {
  const httpLink = createHttpLink({
    uri: `${props.baseUrl}/api/graphql`,
  });
  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
      const serverError = networkError as ServerError;
      if (serverError.statusCode === 401 || serverError.statusCode === 403 ||serverError.statusCode === 500) {
        clearStorage();
        props.setCurrentUser(undefined);
        window.location.pathname = '/';
      }
    }
  });

  const authLink = setContext((_, { headers }) => (
    {
      headers: {
        ...headers,
        'access-token': props.currentUser.auth.token,
        client: props.currentUser.auth.client,
        uid: props.currentUser.auth.uid,
        service: 'simulator'
      }
    }
  ));

  const link = ApolloLink.from([
    authLink,
    errorLink,
    httpLink,
  ]);

  const client = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>{props.children}</ApolloProvider>
  )
}
